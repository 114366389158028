import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { Column, ColumnType } from './Column';

function App() {
  const baseUrl = 'https://api.readpdf.bryanyeo.dev';
  const [file, setFile] = useState(null);
  const [accountName, setAccountName] = useState('');
  const [transactions, setTransactions] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf']
    },
    multiple: false
  });

  const handleAccountNameChange = (event) => {
    setAccountName(event.target.value);
  };

  const handleUpload = async () => {
    if (!file || !accountName) {
      alert('Please select a file and enter an account name');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('accountName', accountName);

    try {
      const response = await axios.post(baseUrl + '/api/transaction/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response.data);
      console.log(response.data.transactions);
      if (response.data?.transactions?.length > 0) {
        setTransactions(response.data.transactions);
        setSelectedRows([]);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Error uploading file');
    }
  };

  const handleDownload = async () => {
    if (selectedRows.length === 0) {
      alert('Please select at least one transaction to export');
      return;
    }
    const rows = selectedRows.map((idx) => transactions[idx]);
    const model = { transactions: rows };

    try {
      const response = await axios.post(baseUrl + '/api/transaction/export', model, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const today = new Date(Date.now());
      const todayDay = today.toLocaleDateString();
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${todayDay}.tsv`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error downloading file:', error);
      alert('Error downloading file');
    }
  };

  const handleCellUpdate = (index, field, value) => {
    setTransactions(prevTransactions => {
      const updatedTransactions = [...prevTransactions];
      updatedTransactions[index] = {
        ...updatedTransactions[index],
        [field]: value
      };
      return updatedTransactions;
    });
  };

  const handleRowSelection = (index) => {
    setSelectedRows(prevSelected => {
      if (prevSelected.includes(index)) {
        return prevSelected.filter(i => i !== index);
      } else {
        return [...prevSelected, index];
      }
    });
  };

  const handleSelectAll = () => {
    if (selectedRows.length === transactions.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(transactions.map((_, index) => index));
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center p-4">
      <div className="bg-white shadow-md rounded-lg p-4 sm:p-6 w-full max-w-4xl">
        <h1 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-4">Transactions Converter</h1>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="accountName">
            Account Name
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="accountName"
            type="text"
            placeholder="Enter account name"
            value={accountName}
            onChange={handleAccountNameChange}
          />
        </div>
        <div className="mb-4">
          <div {...getRootProps()} className={`p-4 sm:p-6 border-2 border-dashed rounded-lg cursor-pointer ${isDragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300'}`}>
            <input {...getInputProps()} />
            {file ? (
              <p className="text-gray-700 text-sm sm:text-base">Selected file: {file.name}</p>
            ) : isDragActive ? (
              <p className="text-blue-500 text-sm sm:text-base">Drop the PDF file here...</p>
            ) : (
              <p className="text-gray-500 text-sm sm:text-base">Drag and drop a PDF file here, or tap to select a file</p>
            )}
          </div>
        </div>
        <div className="flex flex-col sm:flex-row justify-between mb-4">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mb-2 sm:mb-0"
            onClick={handleUpload}
          >
            Upload
          </button>
          <button
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={handleDownload}
          >
            Export
          </button>
        </div>
        {transactions.length > 0 && (
          <div className="mt-4 overflow-x-auto">
            <h2 className="text-xl font-bold text-gray-900 mb-2">Uploaded Transactions</h2>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white border border-gray-300">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="py-2 px-4 border-b">
                      <input
                        type="checkbox"
                        checked={selectedRows.length === transactions.length}
                        onChange={handleSelectAll}
                        className="form-checkbox h-5 w-5 text-blue-600"
                      />
                    </th>
                    <th className="py-2 px-4 border-b">Date</th>
                    <th className="py-2 px-4 border-b">Account</th>
                    <th className="py-2 px-4 border-b">Amount</th>
                    <th className="py-2 px-4 border-b">Category</th>                  
                    <th className="py-2 px-4 border-b">Subcategory</th>
                    <th className="py-2 px-4 border-b">Description</th>
                    <th className="py-2 px-4 border-b">Note</th>
                    <th className="py-2 px-4 border-b">Status</th>                    
                    <th className="py-2 px-4 border-b">Type</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions.map((transaction, index) => (
                    <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                      <td className="py-2 px-4 border-b">
                        <input
                          type="checkbox"
                          checked={selectedRows.includes(index)}
                          onChange={() => handleRowSelection(index)}
                          className="form-checkbox h-5 w-5 text-blue-600"
                        />
                      </td>
                      <Column value={transaction.date} type={ColumnType.Date} onUpdate={(value) => handleCellUpdate(index, 'date', value)} />
                      <Column value={transaction.account} type={ColumnType.Text} onUpdate={(value) => handleCellUpdate(index, 'account', value)} />
                      <Column value={transaction.amount} type={ColumnType.Text} onUpdate={(value) => handleCellUpdate(index, 'amount', value)} />
                      <Column value={transaction.category} type={ColumnType.Text} onUpdate={(value) => handleCellUpdate(index, 'category', value)} />
                      <Column value={transaction.subcategory} type={ColumnType.Text} onUpdate={(value) => handleCellUpdate(index, 'subcategory', value)} />
                      <Column value={transaction.description} type={ColumnType.Text} onUpdate={(value) => handleCellUpdate(index, 'description', value)} />
                      <Column value={transaction.note} type={ColumnType.Text} onUpdate={(value) => handleCellUpdate(index, 'note', value)} />
                      <Column value={transaction.status} type={ColumnType.Text} onUpdate={(value) => handleCellUpdate(index, 'status', value)} />
                      <Column value={transaction.type} type={ColumnType.Text} onUpdate={(value) => handleCellUpdate(index, 'type', value)} />
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
