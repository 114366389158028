import React, { useState } from 'react';
import { format } from 'date-fns';

export const ColumnType = {
  Text: 'Text',
  Date: 'Date',
}

export const Column = ({ value, type, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(value);

  const handleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
    if (editValue !== value) {
      onUpdate(editValue);
    }
  };

  const handleChange = (e) => {
    setEditValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleBlur();
    }
  };

  let displayValue = value;
  if (type === ColumnType.Date) {
    displayValue = format(new Date(value), 'dd/MM/yyyy');
  }

  return (
    <td 
      className="py-2 px-4 border-b cursor-pointer" 
      onClick={handleClick}
    >
      {isEditing ? (
        <input
          type={type === ColumnType.Date ? 'date' : 'text'}
          value={editValue}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          autoFocus
          className="w-full p-2 border rounded text-base"
          onClick={(e) => e.stopPropagation()} // Prevent row selection when clicking on input
        />
      ) : (
        <div className="min-h-[36px] flex items-center">{displayValue}</div>
      )}
    </td>
  );
}